import { SEO } from "gatsby-theme-core";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@lendex/helper/methods";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";

import {
    HeroOne,
    ServiceOne,
    SkillOne,
    ClientOne,
    Funfact,
    Testimonial,
    Project,
    BlogOne,
} from "gatsby-theme-container";

const DefaultHome = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Freelancer" />
            <HeaderOne data={{ ...data.header, ...data.navigation }} />
            <HeroOne data={content?.["hero-section"]} />
            <ServiceOne data={content?.["service-section"]} />
            <SkillOne data={content?.["skill-section"]} />
            <Funfact data={content?.["funfact-section"]} />
            <Project
                data={{
                    ...content?.["project-section"],
                    items: data?.allProject?.nodes,
                }}
            />
            <Testimonial data={content?.["testimonial-section"]} />
            <ClientOne data={content?.["favourite-clients-section"]} />
            <BlogOne
                data={{
                    ...content?.["latest-blog-section"],
                    items: data?.allArticle?.nodes,
                }}
            />
            <FooterOne data={{ ...data.footer, ...data?.contact }} />
        </Layout>
    );
};
export const query = graphql`
    query DefaultHomeQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "freelancer" }) {
            content {
                ...PageContent
            }
        }
        allProject(limit: 8) {
            nodes {
                ...Project01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article01
            }
        }
    }
`;
DefaultHome.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allProject: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default DefaultHome;
